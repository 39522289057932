import React from "react";
import './SettingsControl.css';
import settingsIcon from '../images/settingsIcon.png';

class SettingsControl extends React.Component {

  constructor(props){
    super(props);
    this.state = {};

    this.toggleSettingsPanel = this.toggleSettingsPanel.bind(this);
  }

  toggleSettingsPanel(){
    if(this.props.isOpen){
      this.props.closePanel();
    }else{
      this.props.openPanel();
    }
  }

  


  render(){

    return (
      <div className="settings-panel-button-container">
        <div className="settings-panel-button" onClick={this.toggleSettingsPanel}><img className="settings-icon" src={settingsIcon}></img></div>
      </div>
    );
  }
}

export default SettingsControl;
