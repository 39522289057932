import Matchup from './Matchup';
import Round from './Round';

export function getAllMatchups(tourneyType, competitorsPerMatchup, competitors){
    let numberOfCompetitors = competitors.length;
    let numberOfRounds = Math.ceil(getLog(competitorsPerMatchup, numberOfCompetitors));
    // let numberOfFirstRoundMatchups = Math.pow(competitorsPerMatchup, numberOfRounds-1);
    // let numberOfPlayinMatchups = numberOfCompetitors % Math.pow(competitorsPerMatchup, numberOfRounds);
    // let numberOfByes = numberOfCompetitors - (numberOfPlayinMatchups * 2);

    let firstRoundSeeding = getFirstRoundSeeding(competitorsPerMatchup, numberOfRounds);
    let bracket = [];
    bracket[0] = new Round(0, getFirstRoundMatchups(firstRoundSeeding, competitors)); 
    bracket = bracket.concat(getRemainingMatchups(numberOfRounds, competitorsPerMatchup));
    updateByesToNextRound(bracket, competitorsPerMatchup);
    return bracket;
}

function getFirstRoundMatchups(firstRoundSeeding, competitors){
    let firstRoundMatchups = [];
    for(let i = 0; i<firstRoundSeeding.length; i=i+2){
        let seed1 = firstRoundSeeding[i];
        let seed2 = firstRoundSeeding[i+1];
        let competitor1 = null;
        let competitor2 = null;
        for(let j = 0; j < competitors.length; j++){
            if(competitors[j].seed === seed1 + 1){
                competitor1 = competitors[j];
            }else if(competitors[j].seed === seed2 + 1){
                competitor2 = competitors[j];
            }
        }

        let matchup = new Matchup([competitor1, competitor2])
        matchup.group = Math.floor(i / 2);
        matchup.round = 0;
        
        if(competitor1 === null){
            //This is a bye and the competitor advances
            matchup.isBye = true;
            matchup.players[0] = {name: "BYE", isBye: true};
        }
        if(competitor2 === null){
            //This is a bye and the competitor advances
            matchup.isBye = true;
            matchup.players[1] = {name: "BYE", isBye: true};
        }
        firstRoundMatchups.push(matchup);
    }
    return firstRoundMatchups;
}

function getRemainingMatchups(numberOfRounds, competitorsPerMatchup){
    let remainingMatchups = [];
    for(let i = numberOfRounds-1; i > 0; i--){
        let numberOfMatchupsInRound = Math.pow(competitorsPerMatchup, i - 1);
        let matchupsInRound = [];
        for(let j = 0; j < numberOfMatchupsInRound; j++){
            let matchup = new Matchup([null, null]);
            matchup.group = j;
            matchup.round = numberOfRounds - i;
            matchupsInRound.push(matchup);
        }
        let round = new Round(numberOfRounds - i, matchupsInRound);
        if(round.roundNumber == numberOfRounds - 1){
            round.alternateName = 'Championship';
        }
        remainingMatchups.push(round);
    }
    return remainingMatchups;
}

function updateByesToNextRound(bracket, competitorsPerMatchup){
    let currentRound = 0;
    let nextRound = 1;
    if(bracket.length > 1){
        bracket[currentRound].matchups.forEach(currentMatchup => {
            if(currentMatchup.isBye){
                let nextRoundGroup = Math.floor(currentMatchup.group / competitorsPerMatchup);
                let newPosition = currentMatchup.group % competitorsPerMatchup;
                let futureMatchup = bracket[nextRound].matchups.find(nextRoundMatchup => {
                    return nextRoundMatchup.group === nextRoundGroup;
                });

                currentMatchup.players.forEach((player, index) => {
                    if(player && !player.isBye){
                        futureMatchup.players[newPosition] = player;
                        currentMatchup.winner = player.id;
                    }
                })
                
            }
        });

    }
}

export function advanceContestantToNextRound(bracket, competitorsPerMatchup, player, currentMatchup, isWinner){
    let nextRound = currentMatchup.round + 1;
    currentMatchup.winner = player.id;
    if(bracket.length > nextRound){
        
        let newPosition = currentMatchup.group % competitorsPerMatchup;
        let futureMatchup = getNextRoundMatchup(bracket, currentMatchup, competitorsPerMatchup);

        //If we're changing the result that will impact an already decided future contest
        if(futureMatchup.winner && !futureMatchup.isDoubleElimPossibleFinal){
            
            futureMatchup.players[newPosition] = player; 

            propogatePlayerOnWinCorrection(bracket, player, currentMatchup, futureMatchup);
        }else{
            futureMatchup.players[newPosition] = player; 
        }            

    }
    return bracket;

}

function propogatePlayerOnWinCorrection(bracket, newPlayer, currentMatchup, futureMatchup){
    let losingPlayer = currentMatchup.players.find(matchupPlayer => {
        return !!matchupPlayer && matchupPlayer.id !== newPlayer.id;
    });
    if(futureMatchup.winner === losingPlayer.id){
        futureMatchup.winner = newPlayer.id;
    }
    futureMatchup = getNextRoundMatchup(bracket, futureMatchup, 2);
    if(futureMatchup){
        let positionOfPlayerInMatchup = getPositionOfPlayerInMatchup(futureMatchup, losingPlayer);
        while(positionOfPlayerInMatchup >= 0 && !futureMatchup.isDoubleElimPossibleFinal){
            futureMatchup.players[positionOfPlayerInMatchup] = newPlayer;
            if(futureMatchup.winner === losingPlayer.id){
                futureMatchup.winner = newPlayer.id;
            }
            futureMatchup = getNextRoundMatchup(bracket, futureMatchup, 2);
    
            //If we get to the end of the bracket
            if(futureMatchup == null){
                positionOfPlayerInMatchup = -1;
            }else{
                positionOfPlayerInMatchup = getPositionOfPlayerInMatchup(futureMatchup, losingPlayer);
            }
        }

        if(futureMatchup.isDoubleElimPossibleFinal && positionOfPlayerInMatchup === 0){
            futureMatchup.players[positionOfPlayerInMatchup] = newPlayer;
            if(futureMatchup.winner === losingPlayer.id){
                futureMatchup.winner = newPlayer.id;
            }
        }

    }
}

function getNextRoundMatchup(bracket, currentMatchup, competitorsPerMatchup){
    let futureMatchup = null;
    let nextRound = currentMatchup.round + 1;
    if(bracket.length > nextRound){
        let nextRoundGroup = Math.floor(currentMatchup.group / competitorsPerMatchup);
        let newPosition = currentMatchup.group % competitorsPerMatchup;
        futureMatchup = bracket[nextRound].matchups.find(nextRoundMatchup => {
            return nextRoundMatchup.group === nextRoundGroup;
        });
    }
    return futureMatchup;
}

function getPlayerInMatchup(matchup, targetPlayer){
    let playerInMatchup = matchup.players.find(player => {
        return (player.id === targetPlayer.id);
    });
    return playerInMatchup;
}

function getPositionOfPlayerInMatchup(matchup, targetPlayer){
    let playerPositionInMatchup = matchup.players.findIndex((player, index) => {
        return (!!player && player.id === targetPlayer.id);
    });
    return playerPositionInMatchup;
}


function getFirstRoundSeeding(competitorsPerMatchup, numberOfRounds){
    let numberOfCompetitorsWithoutByes = Math.pow(competitorsPerMatchup, numberOfRounds)

    let seeds = [];
    for(let i = 0; i < numberOfCompetitorsWithoutByes; i++){
        seeds.push(i);
    }


    let slice = 1
    while (slice < seeds.length/2){
        let temp = seeds;
        seeds = []
    
        while (temp.length > 0){
            seeds = seeds.concat(temp.splice(0, slice));       // n from the beginning
            seeds = seeds.concat(temp.splice(temp.length - slice, slice));  // n from the end
        }

        slice *= 2
    }

    return seeds;
}



function getLog(base, x){
    return Math.log10(x)/Math.log10(base);
}


export function getContestantsFromBracket(bracket){

    let contestants = [];
    bracket[0].matchups.forEach(matchup => {
        if(matchup.players[0].id){
            contestants.push(matchup.players[0]);
        }
        if(matchup.players[1].id){
            contestants.push(matchup.players[1]);
        }
    });

    contestants = contestants.sort((a, b) => {
        if(a.seed <= b.seed){
            return -1;
        }else{
            return 1;
        }
    })

    return contestants;
}