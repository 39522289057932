import React from "react";
// import '../BracketDisplay.css';
import {getAllMatchups, advanceContestantToNextRound} from "../BracketCreationHelper";
import BracketDisplay from "../BracketDisplay";
import { 
  getDoubleElimMatchups, 
  advanceDoubleElimContestantToNextRound, 
  moveLoserToDoubleElimBracket, 
  updateFinalRoundOfMainBracket,
  advanceLoserForFinalMatch,
  clearFinalMatch,
  addDoubleRoundsToMainBracket
} from "../DoubleElimBracketHelper";


class BracketContainer extends React.Component {

  constructor(props){
    super(props);

    let bracket = null;
    let doubleElimBracket = null;

    if(this.props.defaultBracket){
      bracket = this.props.defaultBracket;
      doubleElimBracket = this.props.defaultDoubleElimBracket;
    }else{
      if(this.props.contestants.length > 1){
        bracket = getAllMatchups('single', 2, this.props.contestants);
        
        if(this.props.contestants.length > 3){
          let doubleElimSetup = getDoubleElimMatchups(bracket);
          bracket = doubleElimSetup.bracket;
          doubleElimBracket = doubleElimSetup.losersBracket;
          bracket = addDoubleRoundsToMainBracket(bracket, doubleElimBracket);
        }
      }
    }

    this.state = {
      bracket: bracket,
      competitorsPerMatchup: 2,
      doubleElimBracket: doubleElimBracket,
      contestants: this.props.contestants,
      tourneyName: this.props.tourneyName
    };


    if(this.props.saveBrackets){
        this.props.saveBrackets({bracket: bracket, doubleElimBracket: doubleElimBracket});
    }

    this.advanceContestant = this.advanceContestant.bind(this);
    this.advanceDoubleElimContestant = this.advanceDoubleElimContestant.bind(this);
    this.recreateBracket = this.recreateBracket.bind(this);
    this.getDoubleElimDisplay = this.getDoubleElimDisplay.bind(this);
    this.getBracketDisplay = this.getBracketDisplay.bind(this);
  }

  

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.contestants !== this.props.contestants && this.props.contestants.length > 1){
      this.recreateBracket();
    }
    if (prevProps.isDoubleElim !== this.props.isDoubleElim) {
      let bracket = this.state.bracket;
      if(this.props.isDoubleElim){
        bracket = addDoubleRoundsToMainBracket(this.state.bracket, this.state.doubleElimBracket);
      }else{
        bracket.pop();
        bracket.pop();
        bracket[bracket.length - 1].alternateName = 'Championship';
      }

      this.setState({bracket: bracket});
    }

    if(this.props.saveBrackets){
      if(prevState.bracket != this.state.bracket || prevState.doubleElimBracket != this.state.doubleElimBracket){
        this.props.saveBrackets({bracket: this.state.bracket, doubleElimBracket: this.state.doubleElimBracket});
      }
    }
  }

  resetBracket(){
    this.recreateBracket();
  }


  
  advanceContestant(winner, loser, matchup){
    let bracket = this.state.bracket;
    let doubleElimBracket = this.state.doubleElimBracket;

    if(matchup.isDoubleElimPossibleFinal){
      if(matchup.players[1].id === winner.id){
        bracket = advanceContestantToNextRound(this.state.bracket, this.state.competitorsPerMatchup, winner, matchup, true);
        bracket = advanceLoserForFinalMatch(bracket, loser);
      }else{
        bracket = clearFinalMatch(bracket, matchup, winner);
      }

    }else{
      bracket = advanceContestantToNextRound(bracket, this.state.competitorsPerMatchup, winner, matchup, true);
      if(this.props.isDoubleElim && matchup.nextOnLoss){
        doubleElimBracket = moveLoserToDoubleElimBracket(bracket, this.state.doubleElimBracket, this.state.competitorsPerMatchup, loser, matchup);
        this.setState({doubleElimBracket: doubleElimBracket});
      }
    }

    this.setState({bracket: bracket});
    this.props.saveBrackets({bracket: bracket, doubleElimBracket: doubleElimBracket});
  }

  advanceDoubleElimContestant(winner, loser, matchup){
    let bracket = this.state.bracket;
    let doubleElimBracket = advanceDoubleElimContestantToNextRound(this.state.bracket, this.state.doubleElimBracket, this.state.competitorsPerMatchup, winner, matchup, true);
    if(doubleElimBracket.length === matchup.round + 1){
      bracket = updateFinalRoundOfMainBracket(this.state.bracket, winner);
      this.setState({bracket: bracket});
    }
    this.setState({doubleElimBracket: doubleElimBracket});
    this.props.saveBrackets({bracket: bracket, doubleElimBracket: doubleElimBracket});
  }

  recreateBracket(){
    
    if(this.props.contestants.length > 1){
      let bracket = getAllMatchups('single', 2, this.props.contestants);
      let doubleElimBracket = null;

      if(this.props.contestants.length > 3){
        
        let doubleElimSetup = getDoubleElimMatchups(bracket);
        bracket = doubleElimSetup.bracket;
        doubleElimBracket = doubleElimSetup.losersBracket;
        if(this.props.isDoubleElim){
          bracket = addDoubleRoundsToMainBracket(bracket, doubleElimBracket);
        }
      }

      this.setState({
        bracket: bracket,
        competitorsPerMatchup: 2,
        doubleElimBracket: doubleElimBracket,
        contestants: this.props.contestants,
        isDoubleElim: this.props.isDoubleElim
      });
    }
  }

  getDoubleElimDisplay(){

    let hideByes = this.props.hideByes !== undefined ? this.props.hideByes : false;
    let isDoubleElim = this.props.isDoubleElim !== undefined ? this.props.isDoubleElim : false;

    let doubleElimDisplay = (<div></div>);
    if(isDoubleElim && this.state.doubleElimBracket){
      doubleElimDisplay = <BracketDisplay header="Double Elimination Bracket" bracket={this.state.doubleElimBracket} contestants={this.props.contestants} advanceContestant={this.advanceDoubleElimContestant} hideByes={hideByes} isLocked={this.props.isLocked}></BracketDisplay>
    }

    return doubleElimDisplay;
  }

  getBracketDisplay(){

    let hideByes = this.props.hideByes !== undefined ? this.props.hideByes : false;

    let bracketDisplay = (<div></div>);
    if(this.state.bracket){
      bracketDisplay = (<div className="main-bracket">
        <BracketDisplay header={this.props.tourneyName} bracket={this.state.bracket} contestants={this.props.contestants} advanceContestant={this.advanceContestant} format="tournament" hideByes={hideByes} onReset={this.recreateBracket} isLocked={this.props.isLocked}></BracketDisplay>
        </div>);
    }
    return bracketDisplay;
  }
  
  render(){    
    let bracketDisplay = this.getBracketDisplay();
    let doubleElimDisplay = this.getDoubleElimDisplay();

      return (
        <div>
          {bracketDisplay}
          {doubleElimDisplay}
        </div>
      )
  }
}

export default BracketContainer;
