import React from "react";
import './PublishPanel.css';
import InputBox from "../InputBox";
import {isClickInBox} from "../ClickPositionHelper";
import Constants from '../Constants';


import copyLinkIcon from "../images/copy-link-icon.png";
import openNewTabIcon from "../images/open-new-tab-icon.png";

class PublishPanel extends React.Component {

  constructor(props){
    super(props);
    this.state = {};
    this.bracketLinkRef = React.createRef();

    this.publishBracket = this.publishBracket.bind(this);
    this.closePanelOnOutsideClick = this.closePanelOnOutsideClick.bind(this);
    this.getShareableLinkUI = this.getShareableLinkUI.bind(this);
    this.copyLink = this.copyLink.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.closePanelOnOutsideClick);
  }
 
  closePanelOnOutsideClick(event) {
    let panel = document.querySelector('#publishPanel');
    if(panel){
      if (!isClickInBox(event.x, event.y, panel.getBoundingClientRect())) {
        this.props.closePanel();
      }
    }
  }


  isOS() {
    return navigator.userAgent.match(/ipad|iphone/i);
  }

  createTextArea(text) {
    let textArea = document.createElement('textArea');
    textArea.className = 'hidden';
    textArea.value = text;
    document.body.appendChild(textArea);
    return textArea;
  }

  copyLink(event){
    let copyText = this.createTextArea(this.bracketLinkRef.current.state.value);
    // let form = event.currentTarget.parentElement;
    // var copyText = form.querySelector(".poll-link");
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
    navigator.clipboard.writeText(copyText.value);
    document.body.removeChild(copyText);

    // let copyText = this.bracketLinkRef.current.state;
    // copyText.select();
    // copyText.setSelectionRange(0, 99999); /* For mobile devices */
    // navigator.clipboard.writeText(copyText.value);
    // let form = event.currentTarget.parentElement;
    // var copyText = form.querySelector(".poll-link");
    // copyText.select();
    // copyText.setSelectionRange(0, 99999); /* For mobile devices */
    // if(window.navigator.clipboard){
    //   window.navigator.clipboard.writeText(copyText);
    // }else{
    //   document.execCommand('copy', true, copyText);
    // }
    this.props.showToast('Copied link to clipboard');
    // alert("Copied the text: " + copyText.value);
  }

  openLinkInNewTab(){
      var copyText = document.querySelector(".poll-link");
      window.open(copyText.value, '_blank');
  }

  publishBracket(){
    this.props.publishBracket();
  }

  getShareableLinkUI(){
    if(this.props.bracketId){
      return (
        <div className="poll-link-display">
          <div className="poll-detail-header">Share this bracket with friends</div>
          <div className="poll-detail-subtext">Copy the following link and send it to those you wish to view this bracket</div>
          
          <div className="poll-link-form">
              <InputBox name='poll-link' class='poll-link styled-input'  ref={this.bracketLinkRef} value={Constants.HOST_URL + "/view/?bracketid=" + this.props.bracketId} readonly="readonly"></InputBox>
              <button label='copy-link' className='poll-link-button' onClick={this.copyLink}><img className="copy-link-icon" src={copyLinkIcon}></img></button>
              <button label='open-link' className='poll-link-button' onClick={this.openLinkInNewTab}><img className="open-new-tab-icon" src={openNewTabIcon}></img></button>
          </div>
        </div>
      );
    }else{
      return (<></>);
    }
    
  }

  render(){

    return (
      <div id="publishPanel" className="publish-panel">
        <div className="publish-panel-contents">


        {this.getShareableLinkUI()}

          <div class="settings-button-container">
            <button className='publish-bracket-button' onClick={this.publishBracket}>Publish Bracket</button>
          </div>

        </div>
      </div>
    );
  }
}

export default PublishPanel;
