import React from "react";
import './PublishControl.css';
import publishIcon from '../images/publish-icon.png';

class PublishControl extends React.Component {

  constructor(props){
    super(props);
    this.state = {};

    this.togglePublishPanel = this.togglePublishPanel.bind(this);
  }

  togglePublishPanel(){
    if(this.props.isOpen){
      this.props.closePanel();
    }else{
      this.props.openPanel();
    }
  }

  


  render(){

    return (
      <div className="publish-button-container">
        <div className="publish-button" onClick={this.togglePublishPanel}><img className="publish-icon" src={publishIcon}></img></div>
      </div>
    );
  }
}

export default PublishControl;
