import React from "react";
import './BracketDisplay.css';
import BracketRound from "./BracketRound";
import resetIcon from './images/reset-icon.png';
import Round from "./Round";
import {isAllByes} from "./RoundHelper";

class BracketDisplay extends React.Component {

  constructor(props){
    super(props);
    this.bracketControlRef = React.createRef();

    this.state = {
      roundToDisplay: 'All'
    };
    
    this.displayAllRounds = this.displayAllRounds.bind(this);
    this.changeRoundToDisplay = this.changeRoundToDisplay.bind(this);
  }

  componentDidUpdate(prevProps, prevState){
    if(this.props.contestants && prevProps.contestants != this.props.contestants){
      this.updateRoundToDisplay('All');
    }
  }

  getHeader(){
    if(this.props.header){
      return (
        <div className="bracket-header-container">
            <div className="bracket-header">{this.props.header}</div>
        </div>
      )
    }
    return (<div></div>);
  }

  getBracketControls(){
    return (
      <div className="bracket-controls" ref={this.bracketControlRef}>
        
        {this.getRoundControls()}
        {this.getResetBracketButton()}
      </div>
    )
  }

  getRoundControls(){
    let elements = [(<div className="control-container round-icon selected" data-round="All" onClick={this.changeRoundToDisplay}>All</div>)];
    for(let i = 0; i < this.props.bracket.length; i++){
      elements.push(
        (<div className="control-container round-icon" data-round={i} onClick={this.changeRoundToDisplay}>
        {i+1}
      </div>)
      )
    }

    if(isAllByes(this.props.bracket[0])){
      elements.pop();
    }

    return elements;
  }

  getResetBracketButton(){
    let resetBracketButton = '';
    if(this.props.onReset){
      resetBracketButton = (
      <div className="control-container reset-icon">
        <img src={resetIcon} className="control-icon" onClick={this.props.onReset}></img>
      </div>)
    }
    return resetBracketButton;
  }

  changeRoundToDisplay(event){
    let round = event.currentTarget.dataset.round;
    this.updateRoundToDisplay(round);
  }

  updateRoundToDisplay(round){
    let roundIcons = this.bracketControlRef.current.querySelectorAll('.control-container.round-icon')
    roundIcons.forEach((icon) => {
      if(icon.className.includes(' selected')){
        icon.className = icon.className.replace(' selected', '');
      }

      if(icon.dataset.round === round){
        icon.className += ' selected';
      }
    })
    this.setState({roundToDisplay: round});
  }

  displayRound(roundNumber){
    let numberOfAllByeRounds = 0;
    let elements = this.props.bracket.map((round, index) => {
      if(isAllByes(round)){
        numberOfAllByeRounds++;
      }
      return (<BracketRound key={index} matchups={round.matchups} bracketRound={round} round={index+1-numberOfAllByeRounds} advanceContestant={this.props.advanceContestant} hideByes={this.props.hideByes} format={this.props.format} individualRound="true" isLocked={this.props.isLocked}></BracketRound>)
    })
    for(let i = 0; i < numberOfAllByeRounds; i++){
      elements.shift()
    }
    return (  
      <section id="bracket">
      {this.getHeader()}
        <div className="container">
      {this.getBracketControls()}
          <div className="split split-one">
            {elements[roundNumber]}
          </div>
        </div>
      </section>
      )

  }

  displayAllRounds(){
    let numberOfAllByeRounds = 0;
    let elements = this.props.bracket.map((round, index) => {
      if(isAllByes(round)){
        numberOfAllByeRounds++;
      }
      return (<BracketRound key={index} matchups={round.matchups} bracketRound={round} round={index+1-numberOfAllByeRounds} advanceContestant={this.props.advanceContestant} hideByes={this.props.hideByes} format={this.props.format} isLocked={this.props.isLocked}></BracketRound>)
    })

    for(let i = 0; i < numberOfAllByeRounds; i++){
      elements.shift()
    }
    
      return (  
      <section id="bracket">
      {this.getHeader()}
        <div className="container">
      {this.getBracketControls()}
          <div className="split split-one">
            {elements}
          </div>
        </div>
      </section>
      )
  }

  render(){
    if(this.state.roundToDisplay === 'All'){
      return this.displayAllRounds();
    }else{
      return this.displayRound(this.state.roundToDisplay);
    }
  }
}

export default BracketDisplay;
