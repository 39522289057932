export default function getURLParam(paramKey){
  // const queryString = window.location.search;
  // console.log(queryString);
  // const urlParams = new URLSearchParams(queryString);
  
  // let queryValue = null;
  // if(urlParams.has(paramKey)){
  //   queryValue = urlParams.get(paramKey);
  // }
  // return queryValue;
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
      return params[paramKey]; // "some_value"
}
