import Matchup from './Matchup';
import Round from './Round';

export function getDoubleElimMatchups(bracket){

    let numberOfRounds = bracket.length;
    let losersBracket = [];
    let roundOffset = 0;
    for(let i = 0; i < numberOfRounds; i++){
        let currentRound = i + roundOffset;
        let numberOfPlayersInLastRound_main = bracket[i].matchups.length * 2;
        let numberOfPlayersInLastRound_losers = 0;
        let numberOfMatchesPrevMainBracket = bracket[i].matchups.length;
        let numberOfMatchesPrevLoserBracket = 0;
        let numberOfMatchesAddedFromMain = bracket[i].matchups.length / 2;
        let numberOfMatchesAddedFromLoser = 0;
        if(i > 0){
            numberOfMatchesPrevLoserBracket = losersBracket[currentRound-1].matchups.length;
            numberOfMatchesAddedFromLoser = numberOfMatchesPrevLoserBracket / 2;
        }

        let numberOfMatches = numberOfMatchesAddedFromMain + numberOfMatchesAddedFromLoser;

        //if there's an odd number of teams, add in an interim round from just people that were already in losers bracket
        if((isOdd(numberOfMatches) || hasDecimal(numberOfMatches) || numberOfMatchesAddedFromLoser > numberOfMatchesAddedFromMain) && (numberOfMatchesAddedFromLoser >= 1 || numberOfMatchesAddedFromMain >= 1)){
            let interimMatchups = [];
            losersBracket[currentRound] = [];
            for(let j = 0; j < numberOfMatchesAddedFromLoser; j++){
                let matchup = createMatchup(j, currentRound);
                interimMatchups.push(matchup);
            }
            if(interimMatchups.length > 0){
            
                losersBracket[currentRound] = new Round(currentRound, interimMatchups);
                roundOffset++;
                currentRound++;
                numberOfMatches = numberOfMatchesAddedFromLoser / 2 + numberOfMatchesAddedFromMain;

            }
        }

        //Add more people from winners bracket to losers bracket
        let matchups = [];
        for(let j = 0; j < numberOfMatches; j++){
            let matchup = createMatchup(j, currentRound);
            
            let originalBracketGroup = j;
            if(i === 0){
                originalBracketGroup *=2;
            }

            //if there are fewer players from top bracket entering than from bottom
            if(originalBracketGroup < bracket[i].matchups.length){
                originalBracketGroup = (isOdd(i)) ? bracket[i].matchups.length - 1 - originalBracketGroup : originalBracketGroup;
                bracket[i].matchups[originalBracketGroup].nextOnLoss = {
                    bracket: 'L',
                    round: currentRound,
                    group: j,
                    position: 0
                }
    
                matchup.players[0] = {previousRound: i + 1, previousGroup: originalBracketGroup + 1, placeholder: "Loser"};

                if(i === 0){
                    bracket[i].matchups[originalBracketGroup+1].nextOnLoss = {
                        bracket: 'L',
                        round: currentRound,
                        group: j,
                        position: 1
                    }
                    
                    matchup.players[1] = {previousRound: i + 1, previousGroup: originalBracketGroup + 1 + 1, placeholder: "Loser"};
                }
                matchups.push(matchup);
            }

            
            //get matchup that will send loser to this round
        }
        
        losersBracket[currentRound] = new Round(currentRound, matchups);
                
    }
    
    losersBracket = setByes(bracket, losersBracket, 2);

    return {bracket, losersBracket};
}

export function addDoubleRoundsToMainBracket(bracket, losersBracket){
    let possibleFinalMatchup = createMatchup(0, bracket.length);
    possibleFinalMatchup.players[1] = {previousRound: losersBracket.length - 1, previousGroup: 1, placeholder: "Winner"};
    possibleFinalMatchup.isDoubleElimPossibleFinal = true;
    let possibleFinalRound = new Round(bracket.length, [possibleFinalMatchup]);
    possibleFinalRound.alternateName = 'Championship (Game 1)';
    bracket.push(possibleFinalRound);

    let finalMatchup = createMatchup(0, bracket.length);
    finalMatchup.players[0] = {placeholder: "Only if needed"};
    finalMatchup.players[1] = {placeholder: "Only if needed"};
    finalMatchup.isDoubleElimFinal = true;
    let finalRound = new Round(bracket.length, [finalMatchup]);
    finalRound.alternateName = 'Championship (Game 2)';
    bracket.push(finalRound);

    bracket[bracket.length - 3].alternateName = undefined;
    return bracket;
}

//If number of matches is odd, then we'll have two rounds
//The first round is losersBracket[i-1] / 2
//The second round is losersBracket[i-1].length / 2 / 2 + bracket[i].length / 2 
//Advance round 2 numbers
function isOdd(number){
    return number % 2 == 1;
}

function hasDecimal (number) {
	return !!(number % 1);
}

function createMatchup(group, round){
    let matchup = new Matchup([null, null]);
    matchup.group = group;
    matchup.round = round;
    return matchup;
}
function setByes(bracket, doubleElimBracket, competitorsPerMatchup){
    let mainBracketMatchups = bracket[0].matchups;
    mainBracketMatchups.forEach((matchup, index) => {
        if(matchup.isBye){
            let elimGroup = Math.floor(matchup.group/2);
            let elimPosition = index % 2;
            doubleElimBracket[0].matchups[elimGroup].players[elimPosition] = {name: "BYE", isBye: true};
            doubleElimBracket[0].matchups[elimGroup].isBye = true;
            
            let otherPosition = (elimPosition + 1) % 2;
            if(doubleElimBracket[0].matchups[elimGroup].players[otherPosition].placeholder){
                let nextMatchupResponse = getNextMatchup(doubleElimBracket, competitorsPerMatchup, doubleElimBracket[0].matchups[elimGroup]);
                // let roundOffset = bracket[0].isAllByes() ? 1 : 0;
                let previousMatchup = doubleElimBracket[0].matchups[elimGroup].players[otherPosition];
                nextMatchupResponse.futureMatchup.players[nextMatchupResponse.newPosition] = {placeholder: "Loser", previousGroup: previousMatchup.previousGroup, previousRound: previousMatchup.previousRound}; 
            }
        }
    })
    
    let doubleElimMatchups = doubleElimBracket[0].matchups;
    doubleElimMatchups.forEach((matchup, index) => {
        if(matchup.isBye){
            if(matchup.isOnlyByes()){
                let nextMatchupResponse = getNextMatchup(doubleElimBracket, competitorsPerMatchup, matchup);
                nextMatchupResponse.futureMatchup.players[nextMatchupResponse.newPosition] = {name: "BYE", isBye: true}; 
                nextMatchupResponse.futureMatchup.isBye = true;
            }
        }
    })

    return doubleElimBracket;
}

export function moveLoserToDoubleElimBracket(bracket, doubleElimBracket, competitorsPerMatchup, loser, matchup){
    if(loser){
        let nextMatchupDescribe = matchup.nextOnLoss;
        let nextMatchup = doubleElimBracket[nextMatchupDescribe.round].matchups[nextMatchupDescribe.group];
        
        if(nextMatchup.isBye){
            nextMatchup.players[nextMatchupDescribe.position] = loser;
            let futureMatchup = getNextRoundMatchup(bracket, doubleElimBracket, nextMatchup, competitorsPerMatchup);

            if(futureMatchup && futureMatchup.winner){
                let newLoser = matchup.players.find(matchupPlayer => {
                    return !!matchupPlayer && matchupPlayer.id !== loser.id;
                });
                switchTwoPlayers(bracket, doubleElimBracket,loser,newLoser,futureMatchup.round);
            }
            
            advanceDoubleElimContestantToNextRound(bracket, doubleElimBracket, competitorsPerMatchup, loser, nextMatchup);
        }else{
            let futureMatchup = getNextRoundMatchup(bracket, doubleElimBracket, nextMatchup, competitorsPerMatchup);
            if(futureMatchup && futureMatchup.winner){
                let newLoser = matchup.players.find(matchupPlayer => {
                    return !!matchupPlayer && matchupPlayer.id !== loser.id;
                });
                let nextRound = futureMatchup.isDoubleElimPossibleFinal ? doubleElimBracket.length : nextMatchup.round;
                switchTwoPlayers(bracket, doubleElimBracket, loser, newLoser, nextRound);
            }
            nextMatchup.players[nextMatchupDescribe.position] = loser;
            if(nextMatchup.winner){
                let otherPlayer = matchup.players.find(matchupPlayer => {
                    return !!matchupPlayer && matchupPlayer.id !== loser.id;
                });
                if(nextMatchup.winner === otherPlayer.id){
                    nextMatchup.winner = loser.id;
                }
            }
        }
    }
    return doubleElimBracket;

}

export function advanceDoubleElimContestantToNextRound(bracket, doubleElimBracket, competitorsPerMatchup, player, currentMatchup, switchFuturePlayers){
    let nextRound = currentMatchup.round + 1;
    currentMatchup.winner = player.id;
    
    if(doubleElimBracket.length >= nextRound){
            
        //The last matchup has been changed
        if(nextRound == doubleElimBracket.length){
            let futureMatchup = bracket[bracket.length - 2].matchups[0];
            if(switchFuturePlayers && futureMatchup.winner){

                let losingPlayer = currentMatchup.players.find(matchupPlayer => {
                    return !!matchupPlayer && matchupPlayer.id !== player.id;
                });
                switchTwoPlayers(bracket, doubleElimBracket, player, losingPlayer, nextRound + 1);
            }

        }else{
            let futureMatchupResponse = getNextMatchup(doubleElimBracket, competitorsPerMatchup, currentMatchup);
                
    
            //The bracket has already been filled out past this point, so we need to update all future players
            if(switchFuturePlayers && futureMatchupResponse.futureMatchup.winner){
                
                let losingPlayer = currentMatchup.players.find(matchupPlayer => {
                    return !!matchupPlayer && matchupPlayer.id !== player.id;
                });
                switchTwoPlayers(bracket, doubleElimBracket, player, losingPlayer, futureMatchupResponse.futureMatchup.round);
                
            }
            futureMatchupResponse.futureMatchup.players[futureMatchupResponse.newPosition] = player;

        }
                        
    }
    return doubleElimBracket;
}

function switchTwoPlayers(bracket, doubleElimBracket, player, losingPlayer, currentMatchupRound){

    doubleElimBracket.forEach((round, index) => {
        if(index >= currentMatchupRound){
            round.matchups.forEach(matchup => {
                if(matchup){
                    if(matchup.winner === losingPlayer.id){
                        matchup.winner = player.id;
                    }else if(matchup.winner === player.id){
                        matchup.winner = losingPlayer.id;
                    }
    
                    matchup.players.forEach((matchupPlayer, index) => {
                        if(matchupPlayer){
                            if(matchupPlayer.id === player.id){
                                matchup.players[index] = losingPlayer;
                            }else if(matchupPlayer.id === losingPlayer.id){
                                matchup.players[index] = player;
                            }

                        }
                    })
                } 
            })
        }
    })

    //We can assume the last two matchups are due to double elim championships.  So we can switch those names too
    for(let i = bracket.length-2; i < bracket.length; i++){
        let round = bracket[i];
        
        round.matchups.forEach(matchup => {
            if(matchup){
                //if the first player is the winner, that propogation has already been handled by the main bracket handlers
                if(!matchup.isDoubleElimPossibleFinal || matchup.winner === matchup.players[1].id){
                    if(matchup.winner === losingPlayer.id){
                        matchup.winner = player.id;
                    }else if(matchup.winner === player.id){
                        matchup.winner = losingPlayer.id;
                    }

                }

                matchup.players.forEach((matchupPlayer, index) => {
                    if(matchupPlayer && (!matchup.isDoubleElimPossibleFinal || (matchup.isDoubleElimPossibleFinal && index > 0))){
                        if(matchupPlayer.id === player.id){
                            matchup.players[index] = losingPlayer;
                        }else if(matchupPlayer.id === losingPlayer.id){
                            matchup.players[index] = player;
                        }

                    }
                })
            } 
        })

    }
}


function getNextMatchup(doubleElimBracket, competitorsPerMatchup, currentMatchup){
    let nextRound = currentMatchup.round + 1;

    let nextRoundGroup = Math.floor(currentMatchup.group / competitorsPerMatchup);
    let newPosition = currentMatchup.group % competitorsPerMatchup;

    if(doesNextRoundHaveMembersFromWinnersBracket(doubleElimBracket, currentMatchup)){
        nextRoundGroup = currentMatchup.group;
        newPosition = competitorsPerMatchup - 1;
    }
    let futureMatchup = doubleElimBracket[nextRound].matchups.find(nextRoundMatchup => {
        return nextRoundMatchup.group === nextRoundGroup;
    });  

    return {futureMatchup, newPosition};
}

function getNextRoundMatchup(bracket, doubleElimBracket,  currentMatchup, competitorsPerMatchup){
    let nextRound = currentMatchup.round + 1;
    let futureMatchup = null;
    if(doubleElimBracket.length > nextRound){

        let nextRoundGroup = Math.floor(currentMatchup.group / competitorsPerMatchup);
        let newPosition = currentMatchup.group % competitorsPerMatchup;

        if(doesNextRoundHaveMembersFromWinnersBracket(doubleElimBracket, currentMatchup) && currentMatchup.round > 0){
            nextRoundGroup = currentMatchup.group;
            newPosition = competitorsPerMatchup - 1;
        }
        futureMatchup = doubleElimBracket[nextRound].matchups.find(nextRoundMatchup => {
            return nextRoundMatchup.group === nextRoundGroup;
        });  
    }

    if(doubleElimBracket.length === nextRound){
        futureMatchup = bracket[bracket.length-2].matchups[0];
    }

    return futureMatchup;
}

function getPositionOfPlayerInMatchup(matchup, targetPlayer){
    let playerPositionInMatchup = matchup.players.findIndex((player, index) => {
        return (!!player && player.id === targetPlayer.id);
    });
    return playerPositionInMatchup;
}

function doesNextRoundHaveMembersFromWinnersBracket(doubleElimBracket, currentMatchup){
    let nextRound = currentMatchup.round + 1;
    return (doubleElimBracket[currentMatchup.round].matchups.length == doubleElimBracket[nextRound].matchups.length);
}


export function updateFinalRoundOfMainBracket(bracket, winner){
    bracket[bracket.length - 2].matchups[0].players[1] = winner;
    return bracket;
}

export function advanceLoserForFinalMatch(bracket, loser){
    bracket[bracket.length - 1].matchups[0].players[1] = loser;
    return bracket;
}

export function clearFinalMatch(bracket, matchup, winner){
    let finalMatchup = bracket[bracket.length - 1].matchups[0];
    finalMatchup.players[0] = {placeholder: "Not needed"};
    finalMatchup.players[1] = {placeholder: "Not needed"};
    bracket[bracket.length - 1].matchups[0] = finalMatchup;
    matchup.winner = winner.id;
    return bracket;
}