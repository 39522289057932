import {generateUUID} from './UUIDGenerator';

class Matchup{
    id;
    players;
    round;
    group;
    isBye;
    nextOnLoss;
    winner;

    constructor(players){
        this.id = generateUUID();
        this.players = players;
        // this.round = round;
        // this.group = group;
    }

    isOnlyByes(){
        let byePlayers = this.players.filter(player => {
            if(player){
                return player.isBye;
            }
        });

        return (byePlayers.length === this.players.length);
    }

    hasAllContestants(){
        
        let validPlayers = this.players.filter((player) => {
            return !!player && (player.name || player.isBye);
        });

        return validPlayers.length === this.players.length;
    }
    // getWinner(){
    //     let winner = this.players.filter(player => {
    //         if(player){
    //             return player.isBye;
    //         }
    //     });
    // }

    // getLosers(){
    //     let winner = this.players.filter(player => {
    //         if(player){
    //             return player.isBye;
    //         }
    //     });
    // }

}

export default Matchup;