import React from "react";
import InputBox from "../InputBox";
import RightArrowIcon from "../images/right-arrow.png";
import Constants from "../Constants";
import openNewTabIcon from "../images/open-new-tab-icon.png";

class CreateBracketPanel extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      createId: '',
      bracketsToUpdate: this.props.bracketsToUpdate ? this.props.bracketsToUpdate : []
    };

    this.openCreateLink = this.openCreateLink.bind(this);
    this.setCreateId = this.setCreateId.bind(this);
    this.navigateToCreate = this.navigateToCreate.bind(this);

  }


  openCreateLink(event){
    let bracketId = this.state.createId;
    window.location.href = Constants.HOST_URL + "/create/?bracketid=" + bracketId;
  }

  setCreateId(event){
    let createId = event.currentTarget.value;
    this.setState({createId: createId});
    // this.props.setCreateId(createId);
  }

  navigateToCreate(event){
    let bracketId = event.currentTarget.dataset.name;
    window.location.href = Constants.HOST_URL + "/create/?bracketid=" + bracketId;
  }

  navigateToNew(event){
    let bracketId = event.currentTarget.dataset.name;
    window.location.href = Constants.HOST_URL + "/create";
  }

  render(){
    let listOfBracketsToUpdate = [];
    if(Object.keys(this.state.bracketsToUpdate).length > 0){
        listOfBracketsToUpdate = Object.keys(this.state.bracketsToUpdate).map(id => {
            return (<div key={id} data-name={id} className="clickable-list-item" onClick={this.navigateToCreate}>
                <div className="list-item-label">{this.state.bracketsToUpdate[id].title}</div><img className="open-new-tab-icon" src={openNewTabIcon}></img>
            </div>)
        });
    }

    if(listOfBracketsToUpdate.length > 0){
        listOfBracketsToUpdate = [(<div key="container" className="bracket-id-list-container">{listOfBracketsToUpdate}</div>)];
        listOfBracketsToUpdate.unshift((<div key="label" className="landing-nav-label-text">Recent Brackets</div>))
    }


    return (
    <>
        <div className="landing-panel-container">

            <button className="new-bracket-button" onClick={this.navigateToNew}>New</button>

            <hr className="landing-bracket-divider"/>

            <div className="landing-nav-label-text">Open Bracket</div>
            <div className="tourney-id-input-container">
                <InputBox id="createIdInput" class="landing-nav-input" placeholder="bracket-id..." value={this.state.createId} valueChangedCallback={this.setCreateId}></InputBox>
                <button label='open-link' className='landing-nav-button' onClick={this.openCreateLink}><img className="open-new-tab-icon" src={RightArrowIcon}></img></button>
            </div>

            <hr className="landing-bracket-divider"/>
            
            <div className="bracket-id-list">
                {listOfBracketsToUpdate}
            </div>
        </div>
    </>
    );
  }
}

export default CreateBracketPanel;
