import StorageManager from './StorageManager';
import Constants from '../Constants';

const STORAGE_KEY = 'create-brackets'
const MOCK = Constants.MOCK_STORAGE;

// create-brackets : {
//     id: {name: bracket, lastdate: date},
//     id2: {name: bracket2, lastdate: date},
// }

export function getRecentCreateBrackets(){
    if(MOCK){
        return [{id: '12312123-1231-1232-123123123', title: 'Big Daddy Bracket'}, {id: '83838212-9234-9342-2392492341', title: 'Lil baby bracket'}]; //Mocked
    }
    return StorageManager.getValueAsJSON(STORAGE_KEY);
}

export function setRecentCreateBackets(brackets){
    StorageManager.setValueFromJSON(STORAGE_KEY, brackets);
}

export function addRecentCreateBracket(id, bracket){
    let recentBrackets = StorageManager.getValueAsJSON(STORAGE_KEY);
    let newBrackets = recentBrackets === null ? {} : recentBrackets;
    newBrackets[id] = bracket
    StorageManager.setValueFromJSON(STORAGE_KEY, newBrackets);
    return newBrackets;
}

export function removeSavedCreateBracket(id){

    let recentBrackets = StorageManager.getValueAsJSON(STORAGE_KEY);

    let newBrackets = recentBrackets;
    if(recentBrackets !== null && recentBrackets[id] !== null){
        delete recentBrackets[id];
        newBrackets = recentBrackets;
    }else{
        console.log('bracket did not exist');
        return null;
    }
    StorageManager.setValueFromJSON(STORAGE_KEY, newBrackets);
    return newBrackets;
}

