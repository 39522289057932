import React from "react";
import '../BracketDisplay.css';
import {generateUUID} from "../UUIDGenerator";
import SettingsControl from "./SettingsControl";
import SettingsPanel from "./SettingsPanel";
import BracketContainer from "./BracketContainer";
import HomeControl from "../HomeControl";
import PublishControl from "./PublishControl";
import PublishPanel from "./PublishPanel";
import {createBracket, updateBracket, getAdminBracket} from "../callouts/CalloutFactory";
import Toast from '../Toast';
import checkIcon from '../images/check.svg';
import errorIcon from '../images/error.svg';
import LoadingWall from "../LoadingWall";
import {addRecentCreateBracket} from "../localstorage/StorageHelper_CreateBrackets";
import {addRecentViewBracket} from "../localstorage/StorageHelper_ViewBrackets";
import {getBracketDraft, setBracketDraft} from "../localstorage/StorageHelper_BracketDraft";
import getURLParam from "../ParameterRetrieval";
import {getContestantsFromBracket} from "../BracketCreationHelper";
import Constants from '../Constants';

let TEMP_CONTESTANTS = [
  {name: "Person 1", seed: 1},
  {name: "Person 2", seed: 2},
  {name: "Person 3", seed: 3},
  {name: "Person 4", seed: 4}
];

class AppContainer extends React.Component {

  constructor(props){
    super(props);

    let contestants = TEMP_CONTESTANTS.map(contestant => {
      contestant.id = generateUUID();
      return contestant;
    })

    let defaultTourneyName = 'Tournament Bracket';

    this.state = {
      //setup
      displaySettingsPanel: false,
      displayPublishPanel: false,
      tourneyName: defaultTourneyName,
      isDoubleElim: false,
      hideByes: false,
      contestants: contestants,
      brackets: [],
      toastMessages: [],
      toastPosition: 'top-left',
      isLoading: false,
      viewOnlyBracketId: null,
      originalLoad: false
    };

    this.openSettingsPanel = this.openSettingsPanel.bind(this);
    this.closeSettingsPanel = this.closeSettingsPanel.bind(this);
    this.openPublishPanel = this.openPublishPanel.bind(this);
    this.closePublishPanel = this.closePublishPanel.bind(this);
    this.setDoubleElim = this.setDoubleElim.bind(this);
    this.setHideByes = this.setHideByes.bind(this);
    this.setContestants = this.setContestants.bind(this);
    this.setTourneyName = this.setTourneyName.bind(this);
    this.clearContestants = this.clearContestants.bind(this);
    this.publishBracket = this.publishBracket.bind(this);
    this.saveBrackets = this.saveBrackets.bind(this);
    this.showErrorToast = this.showErrorToast.bind(this);
    this.showInfoToast = this.showInfoToast.bind(this);
    this.getBracket = this.getBracket.bind(this);
    this.navigateToCreate = this.navigateToCreate.bind(this);
    this.navigateToLandingPage = this.navigateToLandingPage.bind(this);
  }

  componentDidMount(){
    this.getBracket();
  }

  getBracket(){
    console.log('retrieving bracket');
    this.setState({isLoading: true});
    let bracketId = getURLParam('bracketid');
    if(bracketId){
      getAdminBracket(bracketId).then(result => {
        console.log(result);
        let bracket = JSON.parse(result.bracket);
        let doubleElimBracket = JSON.parse(result.doubleElimBracket);
        this.setState({
          isDoubleElim: result.isDoubleElim,
          tourneyName: result.title,
          hideByes: result.hideByes,
          defaultBracket: bracket,
          defaultDoubleElimBracket: doubleElimBracket,
          viewOnlyBracketId: result.view_id,
          contestants: getContestantsFromBracket(bracket)
        })
        if(bracket){
          addRecentCreateBracket(bracketId, {title: result.title, lastdate: new Date().getTime()})
        }
        
      }).catch(error => {
        this.showErrorToast('Bracket does not exist');
        console.log('was unable to fetch bracket');
        this.navigateToLandingPage();

      }).finally(() => {
        this.setState({
          isLoading: false,
          originalLoad: true
        });
      });
    }else{
      let draft = getBracketDraft();
      if(draft){
        this.setState({
          isDoubleElim: draft.isDoubleElim,
          tourneyName: draft.title,
          hideByes: draft.hideByes,
          defaultBracket: draft.bracket,
          defaultDoubleElimBracket: draft.doubleElimBracket,
          contestants: getContestantsFromBracket(draft.bracket)
        })
      }
      this.setState({
        isLoading: false,
        originalLoad: true
      });
    }
  }

  publishBracket(){
    console.log('publishing bracket');
    this.setState({isLoading: true});
    if(!getURLParam('bracketid')){
      createBracket({
        title: this.state.tourneyName,
        isDoubleElim: this.state.isDoubleElim,
        hideByes: this.state.hideByes,
        bracket: JSON.stringify(this.state.brackets.bracket),
        doubleElimBracket: JSON.stringify(this.state.brackets.doubleElimBracket)
      }).then(result => {
        console.log(result);
        
        if(result.bracket_id){
          this.setState({viewOnlyBracketId: result.view_id})
          this.showSuccessToast();
        
          addRecentViewBracket(result.view_id, {title: result.title, lastdate: new Date().getTime()})
          addRecentCreateBracket(result.bracket_id, {title: result.title, lastdate: new Date().getTime()})
          setBracketDraft(null);
          setTimeout(() => {
            this.navigateToCreate(result.bracket_id);
          }, 2000);
        }
        
      }).catch(error => {
        this.showErrorToast('There was an error publishing your bracket');
        console.log('was unable to publish bracket');
        this.setState({isLoading: false});
      });
    }else{
      updateBracket({
        bracketId: getURLParam('bracketid'),
        title: this.state.tourneyName,
        isDoubleElim: this.state.isDoubleElim,
        hideByes: this.state.hideByes,
        bracket: JSON.stringify(this.state.brackets.bracket),
        doubleElimBracket: JSON.stringify(this.state.brackets.doubleElimBracket)
      }).then(result => {
        console.log(result);
        
        if(result.bracket_id){
          this.setState({viewOnlyBracketId: result.view_id})
          this.showSuccessToast();
        
          addRecentViewBracket(result.view_id, {title: result.title, lastdate: new Date().getTime()})
          addRecentCreateBracket(result.bracket_id, {title: result.title, lastdate: new Date().getTime()})
        }
        
      }).catch(error => {
        this.showErrorToast('There was an error publishing your bracket');
        console.log('was unable to publish bracket');
      }).finally(() => {
        this.setState({isLoading: false});
      });
    }
    
  }
  
  navigateToCreate(bracketId){
    window.location.replace(Constants.HOST_URL + "/create/?bracketid=" + bracketId);
  }
  
  navigateToLandingPage(){
    window.location.replace(Constants.HOST_URL);
  }

  openSettingsPanel(){
    this.setState({displaySettingsPanel: true});
    this.setState({displayPublishPanel: false});
  }

  closeSettingsPanel(){
    this.setState({displaySettingsPanel: false});
  }
  
  openPublishPanel(){
    this.setState({displayPublishPanel: true});
    this.setState({displaySettingsPanel: false});
  }

  closePublishPanel(){
    this.setState({displayPublishPanel: false});
  }

  setDoubleElim(status){
    this.setState({isDoubleElim: status});
    this.storeDraftLocally(this.state.tourneyName, status, this.state.hideByes, this.state.brackets.bracket, this.state.brackets.doubleElimBracket);
  }

  setHideByes(status){
    this.setState({hideByes: status});
    this.storeDraftLocally(this.state.tourneyName, this.state.isDoubleElim, status, this.state.brackets.bracket, this.state.brackets.doubleElimBracket);
  }

  setContestants(contestantList){
    this.setState({contestants: contestantList})
  }

  setTourneyName(name){
    this.setState({tourneyName: name});
    this.storeDraftLocally(name, this.state.isDoubleElim, this.state.hideByes, this.state.brackets.bracket, this.state.brackets.doubleElimBracket);
  }

  clearContestants(){
    this.setState({contestants: []});
  }

  saveBrackets(brackets){
    this.setState({brackets: brackets});
    this.storeDraftLocally(this.state.tourneyName, this.state.isDoubleElim, this.state.hideByes, brackets.bracket, brackets.doubleElimBracket);
  }

  storeDraftLocally(title, isDoubleElim, hideByes, bracket, doubleElimBracket){
    
    if(!getURLParam('bracketid')){
      setBracketDraft({
        title: title,
        isDoubleElim: isDoubleElim,
        hideByes: hideByes,
        bracket: bracket,
        doubleElimBracket: doubleElimBracket
      });
    }
  }

  showErrorToast(message){
    let toastMessages = [...this.state.toastMessages];
    toastMessages.push({
      id: Math.floor((Math.random() * 101) + 1),
      title: 'Error',
      description: message,
      backgroundColor: '#d9534f',
      icon: errorIcon
    });
    this.setState({toastMessages: toastMessages});
  }

  showSuccessToast(){
    let toastMessages = [...this.state.toastMessages];
    toastMessages.push({
      id: Math.floor((Math.random() * 101) + 1),
      title: 'Success',
      description: 'The bracket was published successfully',
      backgroundColor: '#5cb85c',
      icon: checkIcon
    });
    this.setState({toastMessages: toastMessages});
  }

  showInfoToast(message){
    let toastMessages = [...this.state.toastMessages];
    toastMessages.push({
      id: Math.floor((Math.random() * 101) + 1),
      title: 'Success',
      description: message,
      backgroundColor: '#4a4d4e',
      icon: checkIcon
    });
    this.setState({toastMessages: toastMessages});
  }

  render(){
    let conditionalDisplay = [];
    
    if(this.state.displaySettingsPanel){
      conditionalDisplay.push(
        <div className="settings-panel">
          <SettingsPanel 
            isDoubleElim={this.state.isDoubleElim} 
            hideByes={this.state.hideByes} 
            contestantList={this.state.contestants} 
            tourneyName={this.state.tourneyName}
            setContestants={this.setContestants}  
            setDoubleElim={this.setDoubleElim} 
            setHideByes={this.setHideByes} 
            setTourneyName={this.setTourneyName} 
            closePanel={this.closeSettingsPanel}
            clearContestants={this.clearContestants}>
          </SettingsPanel>
        </div>
      );

    }

    if(this.state.displayPublishPanel){
      conditionalDisplay.push(
        <div className="publish-panel">
          <PublishPanel
            closePanel={this.closePublishPanel}
            publishBracket={this.publishBracket}
            bracketId={this.state.viewOnlyBracketId}
            showToast={this.showInfoToast}
          ></PublishPanel>
        </div>
      );
    }

    if(this.state.originalLoad){
      return (
        <div>
          {conditionalDisplay}
          <HomeControl></HomeControl>
          <SettingsControl isOpen={this.state.displaySettingsPanel} openPanel={this.openSettingsPanel} closePanel={this.closeSettingsPanel}></SettingsControl>
          <PublishControl isOpen={this.state.displayPublishPanel} openPanel={this.openPublishPanel} closePanel={this.closePublishPanel} publishBracket={this.publishBracket}></PublishControl>
          <BracketContainer 
            format="tournament" 
            tourneyName={this.state.tourneyName} 
            isDoubleElim={this.state.isDoubleElim} 
            hideByes={this.state.hideByes} 
            contestants={this.state.contestants} 
            saveBrackets={this.saveBrackets} 
            defaultBracket={this.state.defaultBracket} 
            defaultDoubleElimBracket={this.state.defaultDoubleElimBracket}>
          </BracketContainer>
          <Toast 
            toastList={this.state.toastMessages}
            position={this.state.toastPosition}
            autoDelete="true"
            autoDeleteTime="5000"
          />
          <LoadingWall isLoading={this.state.isLoading}></LoadingWall>
  
        </div>
      )

    }else{
      return (<LoadingWall isLoading={this.state.isLoading}></LoadingWall>);
    }
  }
}

export default AppContainer;
