import React from "react";
import './Panel.css';
import cancelIcon from './images/cancel-icon.png';


class Panel extends React.Component {

    constructor(props){
        super(props);
        this.state = { };
        this.closePanel = this.closePanel.bind(this);

    }

    closePanel(){
        this.props.closePanel();
    }

    render(){
        let panelBackgroundClass = 'panel-background';

        if(!this.props.shouldDisplay){
            panelBackgroundClass += ' hide';
        }

        if(this.props.shouldDisplay){
            return (
                <div className="Panel" className={panelBackgroundClass}> 
                    <div className="panel"> 
    
                        <div className="panel-header">
                            <div></div>
                            <div className="header-text">{this.props.headerText}</div>
                            <div className="close-button-container"><img className="close-button" src={cancelIcon} alt="close-button" onClick={this.closePanel}></img></div>
                        </div>
                        
                        {this.props.content}
                    </div>
                </div>
            );
        }else{
            return (<div></div>);
        }
    }
}

export default Panel;
