import React from "react";
import './HomeControl.css';
import homeIcon from './images/trophy.png';
import Constants from "./Constants";

class homeControl extends React.Component {

  constructor(props){
    super(props);
    this.state = {};

    this.navigateToHome = this.navigateToHome.bind(this);
  }

  navigateToHome(){
    window.location.href = Constants.HOST_URL;
  }

  


  render(){

    return (
      <div className="home-button-container">
        <div className="home-button" onClick={this.navigateToHome}><img className="home-icon" src={homeIcon}></img></div>
      </div>
    );
  }
}

export default homeControl;
