import React from "react";
import '../BracketDisplay.css';
import BracketDisplay from "../BracketDisplay";

import { getViewOnlyBracket } from "../callouts/CalloutFactory";
import getURLParam from "../ParameterRetrieval";
import Toast from '../Toast';
import checkIcon from '../images/check.svg';
import errorIcon from '../images/error.svg';
import LoadingWall from "../LoadingWall";
import {addRecentViewBracket} from "../localstorage/StorageHelper_ViewBrackets";
import Constants from "../Constants";

class ViewOnlyBracketContainer extends React.Component {

  constructor(props){
    super(props);

    let bracket = null;
    let doubleElimBracket = null;

    this.state = {
      bracket: bracket,
      doubleElimBracket: doubleElimBracket,
      isDoubleElim: false,
      tourneyName: "Unknown Tournament",
      toastMessages: [],
      toastPosition: 'top-left',
      hideByes: false
    };

    this.getDoubleElimDisplay = this.getDoubleElimDisplay.bind(this);
    this.getBracketDisplay = this.getBracketDisplay.bind(this);
  }

  componentDidMount(){
    this.getBracket(true);

    if(!Constants.MOCK_API){
      setInterval(() => {
        this.getBracket(false);
        console.log('bracket reloaded...');
      }, 60000);
    }
  }

  
  getBracket(showLoad){
    console.log('retrieving bracket');
    if(showLoad){
      this.setState({isLoading: true});
    }
    let bracketId = getURLParam('bracketid');
    getViewOnlyBracket(bracketId).then(result => {
      console.log(result);
      let bracket = JSON.parse(result.bracket);
      let doubleElimBracket = JSON.parse(result.doubleElimBracket);
      this.setState({
        bracket: bracket,
        doubleElimBracket: doubleElimBracket,
        hideByes: result.hideByes,
        isDoubleElim: result.isDoubleElim,
        tourneyName: result.title
      })
      if(bracket){
        addRecentViewBracket(bracketId, {title: result.title, lastdate: new Date().getTime()})
      }      
    }).catch(error => {
      this.showErrorToast('There was an error loading your bracket');
      console.log('was unable to publish bracket');
    }).finally(() => {
      this.setState({isLoading: false});
    });
  }


  showErrorToast(message){
    let toastMessages = [...this.state.toastMessages];
    toastMessages.push({
      id: Math.floor((Math.random() * 101) + 1),
      title: 'Error',
      description: message,
      backgroundColor: '#d9534f',
      icon: errorIcon
    });
    this.setState({toastMessages: toastMessages});
  }

  showSuccessToast(){
    let toastMessages = [...this.state.toastMessages];
    toastMessages.push({
      id: Math.floor((Math.random() * 101) + 1),
      title: 'Success',
      description: 'The bracket was published successfully',
      backgroundColor: '#5cb85c',
      icon: checkIcon
    });
    this.setState({toastMessages: toastMessages});

  }
  getDoubleElimDisplay(){

    let hideByes = this.state.hideByes !== undefined ? this.state.hideByes : false;
    let isDoubleElim = this.state.isDoubleElim !== undefined ? this.state.isDoubleElim : false;

    let doubleElimDisplay = (<div></div>);
    if(isDoubleElim && this.state.doubleElimBracket){
      doubleElimDisplay = <BracketDisplay header="Double Elimination Bracket" bracket={this.state.doubleElimBracket} hideByes={hideByes} isLocked="true"></BracketDisplay>
    }

    return doubleElimDisplay;
  }

  getBracketDisplay(){

    let hideByes = this.state.hideByes !== undefined ? this.state.hideByes : false;

    let bracketDisplay = (<div></div>);
    if(this.state.bracket){
      bracketDisplay = (<div className="main-bracket">
        <BracketDisplay header={this.state.tourneyName} bracket={this.state.bracket} format="tournament" hideByes={hideByes} isLocked="true"></BracketDisplay>
        </div>);
    }
    return bracketDisplay;
  }
  
  render(){    
    let bracketDisplay = this.getBracketDisplay();
    let doubleElimDisplay = this.getDoubleElimDisplay();

      return (
        <div>
          {bracketDisplay}
          {doubleElimDisplay}
          <Toast 
            toastList={this.state.toastMessages}
            position={this.state.toastPosition}
            autoDelete="true"
            autoDeleteTime="5000"
          />
          <LoadingWall isLoading={this.state.isLoading}></LoadingWall>
        </div>
      )
  }
}

export default ViewOnlyBracketContainer;
