import React from "react";
import '../BracketDisplay.css';
import ViewOnlyBracketContainer from "./ViewOnlyBracketContainer";
import HomeControl from "../HomeControl";

class ViewOnlyAppContainer extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      //setup
      displaySettingsPanel: false,
      displayPublishPanel: false,
      hideByes: false,
      isLoading: false,
      viewOnlyBracketId: null
    };

  }

  render(){
    return (
      <div className="view-only-brackets">
      <HomeControl></HomeControl>
        <div className="breadcrumb-container">
          <div className="tail-container"><div className="left-tail-container"></div></div>
          <div className="view-only-breadcrumb">View Only</div>
          <div className="tail-container"><div className="right-tail-container"></div></div>
        </div>
        <ViewOnlyBracketContainer format="tournament" hideByes={this.state.hideByes} saveBrackets={this.saveBrackets}></ViewOnlyBracketContainer>
      </div>
    )
  }
}

export default ViewOnlyAppContainer;
