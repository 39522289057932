import React from "react";
import './InputBox.css';

class InputBox extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            value: this.props.value,
            type: this.props.type ? this.props.type : 'text'
        };
        this.valueChangedCallback = this.valueChangedCallback.bind(this);
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.value != this.props.value){
            this.setState({value: this.props.value});
        }
    }

    render(){
        if(this.state.type === 'checkbox'){
            return (<input className={this.props.class} type={this.state.type} name={this.props.name} id={this.props.id} placeholder={this.props.placeholder} checked={this.state.value} autoComplete="off" onChange={this.valueChangedCallback}></input>)
        }else if(this.state.type === 'textarea'){
            return <textarea className={this.props.class} name={this.props.name} id={this.props.id} placeholder={this.props.placeholder} value={this.state.value} autoComplete="off" onChange={this.valueChangedCallback} rows={this.props.rows} cols={this.props.cols}></textarea>
        }else{        
            return (<input className={this.props.class} type={this.state.type} name={this.props.name} id={this.props.id} placeholder={this.props.placeholder} value={this.state.value} autoComplete="off" onChange={this.valueChangedCallback}></input>)
        }
    }

    valueChangedCallback(event){
        if(this.state.type == 'checkbox'){
            this.setState({value: event.target.checked});
        }else{
            this.setState({value: event.target.value});
        }
        if(this.props.valueChangedCallback){
            this.props.valueChangedCallback(event);
        }
        
    }
}

export default InputBox;