import CalloutBuilder from "./CalloutBuilder";
import CalloutBuilderMock from "./CalloutBuilderMock";
import Constants from '../Constants';

let calloutBuilder = Constants.MOCK_API ? new CalloutBuilderMock : new CalloutBuilder();

export function getViewOnlyBracket(key){
    return calloutBuilder.getViewOnlyBracket(key);
}

export function getAdminBracket(key){
    return calloutBuilder.getAdminBracket(key);
}

export function createBracket(bracketInfo){
    return calloutBuilder.createBracket(bracketInfo);
}

export function updateBracket(bracketInfo){
    return calloutBuilder.updateBracket(bracketInfo);
}
