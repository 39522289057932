import React from "react";
import './BracketRound.css';
import MatchupDisplay from "./MatchupDisplay";

class BracketRound extends React.Component {

  constructor(props){
    super(props);
    this.state = {};
  }


  render(){
    let elements = this.props.matchups.map(matchup => {
      return (<MatchupDisplay key={matchup.id} matchup={matchup} round={this.props.round} height="70" advanceContestant={this.props.advanceContestant}  hideByes={this.props.hideByes} format={this.props.format} individualRound={this.props.individualRound} isLocked={this.props.isLocked}></MatchupDisplay>)
    });

    if(this.props.bracketRound.alternateName){
      return (
        <div className="round round-one current">
          <div className="round-details">{this.props.bracketRound.alternateName}<br/><span className="date"></span></div>
          {elements}
        </div>
        )

    }else{
      return (
        <div className="round round-one current">
          <div className="round-details">Round {this.props.round}<br/><span className="date"></span></div>
          {elements}
        </div>
        )

    }
      
  }
}

export default BracketRound;
