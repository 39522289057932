import React from "react";
import CreateBracketPanel from "./CreateBracketPanel";
import ViewBracketPanel from "./ViewBracketPanel";
import Panel from "../Panel";
import "./LandingNavigation.css";
import {getRecentCreateBrackets} from "../localstorage/StorageHelper_CreateBrackets";
import {getRecentViewBrackets} from "../localstorage/StorageHelper_ViewBrackets";
import LogoIcon from "../images/tournament-theme.png";
// import './LandingNav.css';
// import AppContainer from "./AppContainer";
// import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom';
// import ViewOnlyAppContainer from "./view/ViewOnlyAppContainer";

class LandingNav extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      displayViewPanel: false,
      displayCreatePanel: false,
      createId: '',
      viewId: '',
      bracketsToUpdate: getRecentCreateBrackets(),
      bracketsToView: getRecentViewBrackets() //Temp: Read bracketsToUpdate from storageManager
    };

    this.openCreatePanel = this.openCreatePanel.bind(this);
    this.openViewPanel = this.openViewPanel.bind(this);
    this.closeViewPanel = this.closeViewPanel.bind(this);
    this.closeCreatePanel = this.closeCreatePanel.bind(this);

  }

  openViewPanel(){
    //open modal with recent modals you've viewed
    this.setState({displayViewPanel: true});
  }

  openCreatePanel(){
    //open modal with most recent brackets you've created
    this.setState({displayCreatePanel: true});
  }

  closeViewPanel(){
    //open modal with recent modals you've viewed
    this.setState({displayViewPanel: false});
  }

  closeCreatePanel(){
    //open modal with recent modals you've viewed
    this.setState({displayCreatePanel: false});
  }

  // openCreateLink(event){
  //   let bracketId = this.state.viewId;
  //   window.location.href = Constants.HOST_URL + "/create/?bracketid=" + bracketId;
  // }

  // setCreateId(event){
  //   let createId = event.currentTarget.value;
  //   this.setState({createId: createId});
  // }

  render(){
 
    return (
    <>
    
      <div className="landing-nav-container">
        <div className="logo-container"><img className="bracket-logo" src={LogoIcon}/> </div>
        <div className="landing-page-masthead">Bracket Generator</div>
        <div className="landing-nav-buttons">
          <div className="nav-button-container view"><button className="nav-button" onClick={this.openViewPanel}>View</button></div>
          <div className="nav-button-container update"><button className="nav-button" onClick={this.openCreatePanel}>Create</button></div>
        </div>
      </div>
        
      <Panel shouldDisplay={this.state.displayViewPanel} headerText="View Options" closePanel={this.closeViewPanel} content={
        (<ViewBracketPanel bracketsToView={this.state.bracketsToView}></ViewBracketPanel>)
      }>
      </Panel>
        
      <Panel shouldDisplay={this.state.displayCreatePanel} headerText="Create Options" closePanel={this.closeCreatePanel} content={
          (<CreateBracketPanel bracketsToUpdate={this.state.bracketsToUpdate}></CreateBracketPanel>)
      }>
      </Panel>
        
    </>
    );
  }
}

export default LandingNav;
