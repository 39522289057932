var StorageManager = function(StorageManager){

	StorageManager.setValue = function(key, value){
		localStorage.setItem(key, value);
	}

	StorageManager.getValue = function(key){
		return (!!localStorage.getItem(key) ? localStorage.getItem(key) : null);
	}
	StorageManager.getValueAsJSON = function(key){
		let stringValue = (!!localStorage.getItem(key) ? localStorage.getItem(key) : null);
		return (stringValue === null) ? null : JSON.parse(stringValue);
	}
	StorageManager.setValueFromJSON = function(key, value){
		localStorage.setItem(key, JSON.stringify(value));
	}

	
	//generic methods
	//key types = [public-key, private-key, server-key, symmetric-key, session-id]
	StorageManager.storeKey = function(keyType, key){
		if(!!keyType){
			localStorage.setItem(keyType, key);
		}
	}

	StorageManager.clearKey = function(keyType){
		localStorage.setItem(keyType, null);
	}

	StorageManager.getKey = function(keyType){
		return (!!localStorage.getItem(keyType) ? unescape(localStorage.getItem(keyType)) : null);
	}

	return StorageManager;

}(StorageManager || {});

export default StorageManager;