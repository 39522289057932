import React from "react";
import './SettingsPanel.css';
import InputBox from "../InputBox";
import {generateUUID} from "../UUIDGenerator";
import {isClickInBox} from "../ClickPositionHelper";

class SettingsPanel extends React.Component {

  constructor(props){
    super(props);

    let defaultRawContestants = '';
    if(this.props.contestantList){
      this.props.contestantList.forEach(contestant => {
        defaultRawContestants += contestant.name + '\n';
      })
    }
    let defaultTourneyName = this.props.tourneyName ? this.props.tourneyName : '';

    this.state = {
      displayPanel: false,
      rawContestantNames: defaultRawContestants,
      contestantList: [],
      contestantCount: 0,
      randomSeeding: this.props.randomSeeding ? this.props.randomSeeding : false,
      tourneyName: defaultTourneyName
    };

    this.setDoubleElim = this.setDoubleElim.bind(this);
    this.setHideByes = this.setHideByes.bind(this);
    this.setRandomSeeding = this.setRandomSeeding.bind(this);
    this.setRawContestants = this.setRawContestants.bind(this);
    this.organizeContestants = this.organizeContestants.bind(this);
    this.closePanelOnOutsideClick = this.closePanelOnOutsideClick.bind(this);
    this.setTourneyName = this.setTourneyName.bind(this);
    this.clearContestants = this.clearContestants.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.closePanelOnOutsideClick);
  }
 
  closePanelOnOutsideClick(event) {
    let panel = document.querySelector('#settingsPanel');
    if(panel){
      if (!isClickInBox(event.x, event.y, panel.getBoundingClientRect())) {
        this.props.closePanel();
      }
    }
  }

  setRawContestants(event){
    this.setState({rawContestantNames: event.target.value});
    this.organizeContestants(event.target.value, this.state.randomSeeding);
  }

  organizeContestants(rawContestantNames, isRandom){

    let currentContestants = rawContestantNames.split("\n");
    if(isRandom){
      currentContestants = this.shuffle([...currentContestants]);
    }

    let contestantList = [];
    let contestantCount = 0;
    currentContestants.forEach((contestant) => {
        if(contestant !== ''){
          contestantCount++;
          contestantList.push({name: contestant, seed: contestantCount, id: generateUUID()});
        }
    });

    this.setState({
      contestantList: contestantList,
      contestantCount: contestantCount
    });

    this.props.setContestants(contestantList);
  }

  setDoubleElim(event){
    let doubleElimStatus = event.target.checked;
    this.props.setDoubleElim(doubleElimStatus);
  }

  setTourneyName(event){
    let name = event.target.value;
    this.props.setTourneyName(name);
  }

  setHideByes(event){
    let hideByesStatus = event.target.checked;
    this.props.setHideByes(hideByesStatus);
  }

  setRandomSeeding(event){
    let isRandomSeeding = event.target.checked;
    this.setState({randomSeeding: isRandomSeeding})
    this.organizeContestants(this.state.rawContestantNames, isRandomSeeding);
  }

  clearContestants(event){
    this.setState({rawContestantNames: ''});
    this.props.clearContestants();
  }

  shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
  }
  render(){

    return (
      <div id="settingsPanel" className="side-panel">
        <div className="side-panel-contents">

          <div className="tourney-name-input-container">
            <div className="label-text">Tourney Name</div>
            <InputBox id="tourneyName" class="tourney-name-input" value={this.props.tourneyName} valueChangedCallback={this.setTourneyName}></InputBox>
          </div>


          <div className="checkbox-container">
            <div className="label-text">Double Elimination Tournament</div>
              <InputBox id="doubleElim" type="checkbox" value={this.props.isDoubleElim} valueChangedCallback={this.setDoubleElim}></InputBox>
          </div>

          
          <div className="checkbox-container">
            <div className="label-text">Hide Byes</div>
            <InputBox id="hideByes" type="checkbox" value={this.props.hideByes} valueChangedCallback={this.setHideByes}></InputBox>
          </div>

          <div className="checkbox-container">
            <div className="label-text">Random Seeding</div>
            <InputBox id="randomSeeding" type="checkbox" value={this.props.randomSeeding} valueChangedCallback={this.setRandomSeeding}></InputBox>
          </div>
          
          <div className="contestant-input-container">
            <div className="label-text">Add Contestants</div>
            <InputBox id="addContestants" type="textarea" value={this.state.rawContestantNames} class="contestant-input" rows="8" valueChangedCallback={this.setRawContestants}></InputBox>
          </div>
          
          <div class="settings-button-container">
            <button className='standout-button' onClick={this.clearContestants}>Clear Contestants</button>
          </div>

        </div>
      </div>
    );
  }
}

export default SettingsPanel;
