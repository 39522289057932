export function isAllByes(round){
    let byeCount = 0;
    round.matchups.forEach(matchup => {
        if(matchup.isBye){
            byeCount++;
        }
    });
    if(byeCount === round.matchups.length){
        return true;
    }
    return false;
}

export function hasAllContestants(players){
  let validPlayers = players.filter((player) => {
    return !!player && (player.name || player.isBye);
  });

  return validPlayers.length === players.length;
}