class Round{
    matchups;
    isAllByes;
    roundNumber;

    constructor(roundNumber, matchups){
        this.roundNumber = roundNumber;
        this.matchups = matchups;
    }

    isAllByes(){
        let byeCount = 0;
        this.matchups.forEach(matchup => {
            if(matchup.isBye){
                byeCount++;
            }
        });
        if(byeCount === this.matchups.length){
            return true;
        }
        return false;
    }

}

export default Round;