import React from "react";
import InputBox from "../InputBox";
import RightArrowIcon from "../images/right-arrow.png";
import Constants from "../Constants";
import "./LandingBracketPanels.css";
import openNewTabIcon from "../images/open-new-tab-icon.png";


class ViewBracketPanel extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      viewId: '',
      bracketsToView: this.props.bracketsToView ? this.props.bracketsToView : []
    };

    this.openViewLink = this.openViewLink.bind(this);
    this.setViewId = this.setViewId.bind(this);
    this.navigateToView = this.navigateToView.bind(this);

  }


  openViewLink(event){
    let bracketId = this.state.viewId;
    window.location.href = Constants.HOST_URL + "/view/?bracketid=" + bracketId;
  }

  setViewId(event){
    let viewId = event.currentTarget.value;
    this.setState({viewId: viewId});
    // this.props.setViewId(viewId);
  }

  navigateToView(event){
    let bracketId = event.currentTarget.dataset.name;
    window.location.href = Constants.HOST_URL + "/view/?bracketid=" + bracketId;
  }

  render(){
    let listOfBracketsToView = [];
    if(Object.keys(this.state.bracketsToView).length > 0){
      listOfBracketsToView = Object.keys(this.state.bracketsToView).map(id => {
            return (<div key={id} data-name={id} className="clickable-list-item" onClick={this.navigateToView}>
                <div className="list-item-label">{this.state.bracketsToView[id].title}</div><img className="open-new-tab-icon" src={openNewTabIcon}></img>
            </div>)
        });
    }

    if(listOfBracketsToView.length > 0){
      listOfBracketsToView = [(<div key="container" className="bracket-id-list-container">{listOfBracketsToView}</div>)];
      listOfBracketsToView.unshift((<div key="label" className="landing-nav-label-text">Recent Brackets</div>))
    }


    return (
    <>
        <div className="landing-panel-container">
            
            <div className="landing-nav-label-text">View Bracket</div>
            <div className="landing-nav-input-container">
                <InputBox id="viewIdInput" class="landing-nav-input" placeholder="bracket-id..." value={this.state.viewId} valueChangedCallback={this.setViewId}></InputBox>
                <button label='open-link' className='landing-nav-button' onClick={this.openViewLink}><img className="open-new-tab-icon" src={RightArrowIcon}></img></button>
            </div>

            <hr className="landing-bracket-divider"/>

            <div className="bracket-id-list">
                {listOfBracketsToView}
            </div>

            {/* <button onClick={navigateToView}>New</button> */}
        </div>
    </>
    );
  }
}

export default ViewBracketPanel;
