import React from "react";
import './MatchupDisplay.css';
import {hasAllContestants} from './RoundHelper';

class MatchupDisplay extends React.Component {

  constructor(props){
    super(props);
    this.state = {};

    this.advanceContestant = this.advanceContestant.bind(this);
  }

  getPaddingBetweenMatchups(){
    let height = this.props.height;
    let previousPadding = 0;
    let topPadding = 0;

    let paddingSizeRound = this.props.round;
    paddingSizeRound = this.props.matchup.isDoubleElimPossibleFinal ? paddingSizeRound - 1 : paddingSizeRound;
    paddingSizeRound = this.props.matchup.isDoubleElimFinal ? paddingSizeRound - 2 : paddingSizeRound;
    if(this.props.individualRound){
      paddingSizeRound = 1;
    }
    if(this.props.format === 'tournament'){
      for(let i = 1; i < paddingSizeRound; i++){
        topPadding = previousPadding * 2 + (height / 2); 
        previousPadding = topPadding;
      }
    }
    


    return {
      paddingTop: + topPadding + "px",
      paddingBottom: + topPadding + "px",
      height: height + "px"
    };
  }

  advanceContestant(event){
    if(!this.props.isLocked){
      let id = event.currentTarget.dataset.name;
      let player = this.props.matchup.players.find((player) => {
        if(player && !player.isBye && player.name){
          return player.id === id
        }
      }) 
      let loser = this.props.matchup.players.find((player) => {
        if(player && !player.isBye && player.name){
          return player.id !== id;
        }
      })

      if(hasAllContestants(this.props.matchup.players)){
        this.props.advanceContestant(player, loser, this.props.matchup);
      }
        
    }
  }

  render(){
    let hideByes = this.props.hideByes;
    let matchupGroup = (this.props.matchup.round + 1) + '.' + (this.props.matchup.group + 1);

    let elements = [];
      elements = this.props.matchup.players.map((contestant, index) => {
        if(this.props.matchup.isBye && hideByes){
          matchupGroup = '';
          let heightStyle = {height: this.props.height};
          return (<div key={index} style={heightStyle}></div>)
        }

        if(contestant){
          if(contestant.isBye){
            return (<li key={index} className="team team-top bye"><span className="score">BYE</span></li>);
          }else if(!contestant.name && contestant.placeholder){
            let previousId = contestant.previousRound ? contestant.previousRound + '.' + contestant.previousGroup : '';
            previousId += ' ' + contestant.placeholder;
            return (<li key={index} className="team team-top to-be-played placeholder">{previousId}<span className="score"></span></li>);
          }else{

            if(this.props.matchup.winner){
              if(contestant.id !== this.props.matchup.winner){
                return (<li key={contestant.id} data-name={contestant.id} className="team team-top loser hoverable" onClick={this.advanceContestant}>{contestant.name}<span className="score">{contestant.seed}</span></li>)
              }else{
                return (<li key={contestant.id} data-name={contestant.id} className="team team-top winner hoverable">{contestant.name}<span className="score">{contestant.seed}</span></li>)
              }
            }else{
              return (<li key={contestant.id} data-name={contestant.id} className="team team-top hoverable" onClick={this.advanceContestant}>{contestant.name}<span className="score">{contestant.seed}</span></li>)
            }
          }
        }else{
          return (<li key={index} className="team team-top to-be-played"><span className="score"></span></li>);
        }
      });

    return (
    <div className="matchup-container">
    {/* <div className="round-id">{matchupGroup}</div> */}
      <ul className="matchup" style={this.getPaddingBetweenMatchups()}>{elements}</ul>
    </div>);
  }
}

export default MatchupDisplay;
