import React from "react";
import './LoadingWall.css';
import loadingIcon from './images/loading-icon.gif';

class LoadingWall extends React.Component {
    constructor(props){
        super(props);
        this.state = {
        };
    }

    render(){

        let classes = 'loading-wall';
        if(!this.props.isLoading){
            classes += ' hide';
        }
        return (
            <div className={classes}>
                <img className="loading-icon" src={loadingIcon}/>
            </div>
        );
    }

}

export default LoadingWall;