import Constants from "../Constants";
// import CookieManager from "./CookieManager";
// let cookieManager = new CookieManager();

class CalloutBuilder {

    // login(params){
    //     const {email, password} = params;

    //     return makePostCallout(Constants.SERVER_URL + '/login', {
    //         email: email,
    //         password: password
    //     });
    // }

    // registerUser(params){
    //     const {email, password, firstName, lastName} = params;
    //     return makePostCallout(Constants.SERVER_URL + '/register', {
    //         firstName: firstName,
    //         lastName: lastName,
    //         email: email,
    //         password: password
    //     });
    // }

    // getUserInfo(key){
    //     return makeGetCallout(Constants.SERVER_URL + '/userinfo', {userId: key});
    // }

    getViewOnlyBracket(key){
        return makeGetCallout(Constants.SERVER_URL + '/viewOnlyBracket', {bracketid: key});
    }
    getAdminBracket(key){
        return makeGetCallout(Constants.SERVER_URL + '/bracket', {bracketid: key});
    }

    createBracket(body){
        return makePostCallout(Constants.SERVER_URL + '/bracket', body);
    }

    updateBracket(body){
        return makePatchCallout(Constants.SERVER_URL + '/bracket', body);
    }
}

function makeGetCallout(endpoint, params){

    return new Promise((resolve, reject) => {
        
        let paramString = Object.keys(params).reduce((previous, current, index) => {
            return index === 0 ? '?' + current + '=' + params[current] : previous + '&' + current + '=' + params[current];
        }, '?');

        const url = endpoint + paramString;
        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                // 'x-access-token': cookieManager.getValue(Constants.COOKIE_USER)
            }
        };

        fetch(url, options).then(response => {
            if(response.status === 400){
                console.log('The requested bracket does not exist');
                reject('The requested bracket does not exist');
            }else{
                response.json().then(data => {
                    console.dir(data);
                    resolve(data);
                }).catch(error => {
                    console.log(error);
                    reject(error);
                });
            }
        }).catch(error => {
            console.dir(error);
            reject(error);
        });
    })
}

function makePostCallout(endpoint, body){

    return new Promise((resolve, reject) => {

        const url = endpoint;
        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                // 'x-access-token': cookieManager.getValue(Constants.COOKIE_USER)
            },
            body: JSON.stringify(body)
        };

        fetch(url, options).then(response => {
            response.json().then(data => {
                console.dir(data);
                resolve(data);
            })
        }).catch(error => {
            console.dir(error);
            reject(error);
        });
    })
}

function makePatchCallout(endpoint, body){

    return new Promise((resolve, reject) => {

        const url = endpoint;
        const options = {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                // 'x-access-token': cookieManager.getValue(Constants.COOKIE_USER)
            },
            body: JSON.stringify(body)
        };

        fetch(url, options).then(response => {
            response.json().then(data => {
                console.dir(data);
                resolve(data);
            })
        }).catch(error => {
            console.dir(error);
            reject(error);
        });
    })
}

export default CalloutBuilder;